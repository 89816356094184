// Vendor
import React, { Component } from "react";
import { Button } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { graphql, withApollo } from "react-apollo";
import compose from "lodash.flowright";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Common
import { GOLF_AREA_BY_SLUG } from "../../common/Queries";
import {
  UPDATE_GOLF_AREA,
  CREATE_GOLF_AREA,
  DELETE_GOLF_AREA
} from "../../common/Mutations";
import withEdit from "../../common/WithEditWrapper";

// App
import GeneralInfo from "./categories/GeneralInfo";
import KeyFigureList from "./categories/KeyFigureList";
import CategoryList from "./categories/CategoryList";
import GolfCourseList from "./categories/GolfCourseList";
import Loader from "../ui/Loader";
import DeleteModal from "../ui/DeleteModal";
import { TimedAlert } from "../ui/TimedAlert";
import CityList from "./categories/CityList";
import SingleTranslationTable from "../ui/SingleTranslationTable";

class GolfDestination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      slug: undefined,
      data: {},
      edits: {},
      isDirty: false,
      saveFeedbackFlags: {
        show: false,
        success: true,
        message: ""
      },
      modalOpen: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onValidSubmit = this.onValidSubmit.bind(this);
    this.postSave = this.postSave.bind(this);
    this.saveFeedbackOnClose = this.saveFeedbackOnClose.bind(this);
  }

  async componentDidMount() {
    const { client } = this.props;
    let slug = this.props.match.params.slug;
    if (slug !== "undefined" && slug !== undefined) {
      let data = (await client.query({
        query: GOLF_AREA_BY_SLUG,
        variables: { slug }
      })).data.GolfArea;
      this.setState({ slug, data, loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  handleChange({ key, value }) {
    this.setState(prevState => ({
      edits: { ...prevState.edits, [key]: value }
    }));
  }

  toggleModal() {
    this.setState(prevState => ({ modalOpen: !prevState.modalOpen }));
  }

  async onValidSubmit() {
    this.setState({ loading: true });
    const { data, edits } = this.state;
    const { createArea, updateArea, save } = this.props;

    let input = { ...edits };
    if (input.fullCoursesObjects) {
      input.courses = input.fullCoursesObjects.map(c => c._id);
      delete input.fullCoursesObjects;
    }

    let saveRes;
    // Update if _id is defined, else create new
    if (data._id) {
      saveRes = await save(updateArea, {
        areaId: data._id,
        input
      });
    } else {
      saveRes = await save(createArea, {
        input
      });
    }

    if (!saveRes.ok) {
      console.log(saveRes.message);
      this.setState({ loading: false }, () =>
        this.postSave(false, "Save failed. Check for missing inputs")
      );
      return;
    }

    this.setState(
      {
        edits: {},
        data: saveRes.golfArea,
        loading: false,
        slug: saveRes.golfArea.slug
      },
      () => this.postSave(true, "Saved")
    );
  }

  postSave(success, message) {
    const { slug } = this.state;
    const { history, match } = this.props;

    if (slug !== match.params.slug)
      history.replace(`/golf-destination/${slug}`);

    this.setState({ saveFeedbackFlags: { show: true, success, message } });
  }

  saveFeedbackOnClose() {
    this.setState({ saveFeedbackFlags: { show: false } });
  }

  render() {
    const { edits, saveFeedbackFlags, modalOpen, loading, data } = this.state;
    const isDirty = Object.keys(edits).length > 0;

    const getProps = fields =>
      this.props.getProps(edits, data, fields, this.handleChange);

    const Description = () => {
      const { description, i18n, onChange } = getProps(["description", "i18n"]);

      return (
        <div className="mb-5">
          <h3 className="mb-2">Description</h3>
          <SingleTranslationTable
            targetKey="description"
            placeholderText="Description"
            value={description}
            i18n={i18n}
            onChange={onChange}
          />
        </div>
      );
    };

    const Summary = () => {
      const { summary, i18n, onChange } = getProps(["summary", "i18n"]);

      return (
        <div className="mb-5">
          <h3 className="mb-2">Summary</h3>
          <SingleTranslationTable
            targetKey="summary"
            placeholderText="Summary"
            value={summary}
            i18n={i18n}
            onChange={onChange}
          />
        </div>
      );
    };

    return (
      <div className="page">
        <DeleteModal
          isOpen={modalOpen}
          toggle={this.toggleModal}
          mutation={DELETE_GOLF_AREA}
          variables={{ areaId: data._id }}
          redirect={{ pathname: "/golf-destinations", search: "?deleted=true" }}
        />

        {loading && <Loader fullscreen={true} />}

        <h1>Golf Destination</h1>

        <AvForm
          onValidSubmit={this.onValidSubmit}
          onInvalidSubmit={() =>
            this.postSave(false, "Incorrect or missing input.")
          }
        >
          <GeneralInfo
            {...getProps([
              "name",
              "slug",
              "country",
              "countryCode",
              "imgId",
              "coordinates",
              "radius",
              "zoom"
            ])}
          />

          <Description />

          <Summary />

          <KeyFigureList {...getProps(["keyFigures"])} />

          <CategoryList {...getProps(["categories"])} />

          <GolfCourseList {...getProps(["fullCoursesObjects"])} />

          <div className="Save-container">
            <div className="Save-container__phantom" />
            <div className="Save-container__main">
              {saveFeedbackFlags.show && (
                <TimedAlert
                  className="alert border"
                  delay={3000}
                  color={saveFeedbackFlags.success ? "success" : "danger"}
                  onClose={this.saveFeedbackOnClose}
                >
                  {saveFeedbackFlags.message}
                </TimedAlert>
              )}
              <Button
                id="btn-save"
                className="btn-lg"
                type="submit"
                color={isDirty ? "secondary" : "gray"}
                disabled={!isDirty}
              >
                Save
              </Button>
            </div>
          </div>
        </AvForm>

        <CityList {...getProps(["cities"])} />

        <div className="d-flex w-100 p-1">
          <Button
            className="btn-lg mx-auto mb-sm-4"
            color="danger"
            type="button"
            onClick={this.toggleModal}
            disabled={!data._id}
          >
            <FontAwesomeIcon icon="trash-alt" className="mr-2" />
            Remove golf destination
          </Button>
        </div>
      </div>
    );
  }
}

const GolfDestinationWithQueries = compose(
  withApollo,
  graphql(UPDATE_GOLF_AREA, {
    name: "updateArea",
    options: {
      variables: {
        areaId: "",
        input: {}
      }
    }
  }),
  graphql(CREATE_GOLF_AREA, {
    name: "createArea",
    options: {
      variables: {
        input: {}
      }
    }
  })
)(withEdit(GolfDestination));

export default GolfDestinationWithQueries;
