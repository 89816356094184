import qs from "qs";

class URLHelper {
  static getParam = (location, param) => {
    const urlParams = URLHelper.queryToState(location);
    return urlParams[param];
  };

  static queryToState = location => qs.parse(location.search.slice(1));
}

export default URLHelper;
