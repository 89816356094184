import gql from "graphql-tag";
import {
  CLUB_ATTRIBUTES,
  COURSE_ATTRIBUTES,
  SEASON_RATE_ATTRIBUTES
} from "./Queries";
import { START_PAGE_FIELDS } from "./GraphQLFields";

// COURSE
export const COURSE_CREATE = gql`
  mutation CreateCourse($input: CourseInput!, $clubId: ID!) {
    createCourse(input: $input, clubId: $clubId) {
      ok
      course {
        ${COURSE_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;

export const COURSE_UPDATE = gql`
  mutation UpdateCourse($input: CourseInput!, $courseId: ID!) {
    updateCourse(input: $input, courseId: $courseId) {
      ok
      course {
        ${COURSE_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;

export const COURSE_DELETE = gql`
  mutation DeleteCourse($courseId: ID!) {
    deleteCourse(courseId: $courseId) {
      ok
      course {
        _id
      }
      returnMessage
    }
  }
`;

// SEASONS
export const SEASON_RATE_UPDATE = gql`
  mutation UpdateSeasonRate($input: SeasonRateInput!, $courseId: ID!, $seasonRateId: ID!) {
    updateSeasonRate(input: $input, courseId: $courseId, seasonRateId: $seasonRateId) {
      ok
      seasonRate {
        ${SEASON_RATE_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;

export const SEASON_RATE_CREATE = gql`
  mutation CreateSeasonRate($input: SeasonRateInput!, $courseId: ID!) {
    createSeasonRate(input: $input, courseId: $courseId) {
      ok
      seasonRate {
        ${SEASON_RATE_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;

export const SEASON_RATE_DELETE = gql`
  mutation DeleteSeasonRate($seasonRateId: ID!, $courseId: ID!) {
    deleteSeasonRate(seasonRateId: $seasonRateId, courseId: $courseId) {
      ok
      returnMessage
    }
  }
`;

// CLUB
export const CLUB_UPDATE = gql`
  mutation UpdateClub($clubId: ID!, $input: ClubInput!) {
    updateClub(clubId: $clubId, input: $input) {
      ok
      club {
        ${CLUB_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;

export const CLUB_CREATE = gql`
  mutation CreateClub($clubInput: ClubInput!, $courses: [CourseInput]) {
    createClub(clubInfo: $clubInput, courses: $courses) {
      ok
      club {
        ${CLUB_ATTRIBUTES}
      }
      returnMessage
    }
  }
`;

// AUTHENTIFICATION
export const USER_SIGNIN = gql`
  mutation Signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      token
      user {
        _id
        firstName
        lastName
        email
        updatedAt
      }
    }
  }
`;

// GOLF AREA
export const UPDATE_GOLF_AREA = gql`
  mutation UpdateGolfArea($areaId: ID!, $input: GolfAreaInput!) {
    updateGolfArea(areaId: $areaId, input: $input) {
      ok
      message
      golfArea {
        _id
        name
        slug
        coordinates {
          latitude
          longitude
        }
        radius
        zoom
        country
        countryCode
        imgId
        keyFigures {
          courses
          sunnyDays
          taxiPrice
          beerPrice
        }
        active
        startPageIndex
        description
        summary
        i18n
        categories {
          imgId
          title
          description
          i18n
        }
        fullCoursesObjects {
          _id
          name
          description
          i18n
        }
        cities {
          name
          imgId
        }
      }
    }
  }
`;

export const CREATE_GOLF_AREA = gql`
  mutation CreateGolfArea($input: GolfAreaInput!) {
    createGolfArea(input: $input) {
      ok
      message
      golfArea {
        _id
        name
        slug
        coordinates {
          latitude
          longitude
        }
        radius
        zoom
        country
        countryCode
        imgId
        keyFigures {
          courses
          sunnyDays
          taxiPrice
          beerPrice
        }
        active
        startPageIndex
        description
        summary
        i18n
        categories {
          imgId
          title
          description
          i18n
        }
        fullCoursesObjects {
          _id
          name
          description
          i18n
        }
        cities {
          name
          imgId
        }
      }
    }
  }
`;

export const DELETE_GOLF_AREA = gql`
  mutation DeleteGolfArea($areaId: ID!) {
    deleteGolfArea(areaId: $areaId) {
      ok
      message
    }
  }
`;

// START PAGE
export const UPDATE_START_PAGE = gql`
  mutation UpdateStartPage($input: StartPageInput!) {
    updateStartPage(input: $input) {
      ok
      startPage{
        ${START_PAGE_FIELDS}
      }
      message
    }
  }
`;

// DASHBOARD
// IMAGES
export const IMAGES_DELETE = gql`
  mutation DeleteImages($input: ImageDeleteInput!) {
    deleteImages(input: $input) {
      ok
      message
      deleted
    }
  }
`;

export const IMAGE_RENAME = gql`
  mutation renameImage($input: ImageRenameInput!) {
    renameImage(input: $input) {
      ok
      message
    }
  }
`;

// RATES
export const MISSING_RATE_TOGGLE_MUTE = gql`
  mutation missingRateToggleMute($id: ID!) {
    missingRateToggleMute(id: $id) {
      ok
      message
      missingRate {
        _id
        name
        rateTypeId
        muted
        clubId {
          name
          slug
          teeSheetProvider
        }
        rateEndDate
      }
    }
  }
`;

export const MISSING_RATE_DELETE = gql`
  mutation missingRateDelete($id: ID!) {
    missingRateDelete(id: $id) {
      ok
      message
      missingRate {
        _id
        name
        rateTypeId
        muted
        clubId {
          name
          slug
          teeSheetProvider
        }
        rateEndDate
      }
    }
  }
`;

export const MISSING_RATE_PURGE_OLD = gql`
  mutation missingRatePurgeOld {
    missingRatePurgeOld {
      ok
      message
      objectsRemoved
    }
  }
`;
