// Vendor
import React, { Component } from "react";

// App
import { RatesExpiringTable } from "../ui/RatesExpiringTable";
import { NoAvailabilityTable } from "../ui/NoAvailabilityTable";
import { RateMismatchComponent } from "../rate-mismatches/RateMismatchComponent";

class Dashboard extends Component {
  render() {
    return (
      <div className="Dashboard page">
        <h1>Dashboard</h1>
        <NoAvailabilityTable />
        <RatesExpiringTable />
        <RateMismatchComponent />
      </div>
    );
  }
}

export default Dashboard;
