// Vendor
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { AvInput, AvForm } from "availity-reactstrap-validation";
import { Button, InputGroup, InputGroupAddon, Label } from "reactstrap";

// App
import { ConnectedCityList } from "../../ui/FunctionalComponents";

class CityList extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    cities: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, imgId: PropTypes.string })
    )
  };
  static defaultProps = {
    cities: []
  };
  constructor(props) {
    super(props);

    this.addCity = this.addCity.bind(this);
    this.removeCity = this.removeCity.bind(this);
    this.validateNoDuplicates = this.validateNoDuplicates.bind(this);
  }

  addCity(_, values) {
    const { cities, onChange } = this.props;
    onChange({ key: "cities", value: [...cities, { ...values }] });
  }

  removeCity(city) {
    const { cities, onChange } = this.props;
    onChange({ key: "cities", value: cities.filter(c => c !== city) });
  }

  validateNoDuplicates(name) {
    const { cities } = this.props;
    return !cities.some(c => c.name === name);
  }

  render() {
    const { cities } = this.props;
    return (
      <div className="mb-5">
        <h3 className="mb-3">Cities</h3>

        <AvForm
          className="mb-3"
          onValidSubmit={this.addCity}
          beforeSubmitValidation={e => e.stopPropagation()}
        >
          <InputGroup size="lg">
            <div className="float-input-group">
              <AvInput
                style={{
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0"
                }}
                id="cityName"
                name="name"
                placeholder="Name"
                type="text"
                required
                validate={{ async: this.validateNoDuplicates }}
              />
              <Label for="cityName">Name</Label>
            </div>
            <div className="float-input-group">
              <AvInput
                className="rounded-0"
                name="imgId"
                id="cityImgId"
                placeholder="Image Id"
                type="text"
                required
              />
              <Label for="cityImgId">Image Id</Label>
            </div>
            <InputGroupAddon addonType="append">
              <Button color="primary" type="submit">
                Add
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </AvForm>

        <ConnectedCityList cities={cities} onClickHandler={this.removeCity} />
      </div>
    );
  }
}

export default CityList;
