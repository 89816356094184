// Vendor
import React from "react";
import { Button, ButtonGroup, ButtonToolbar } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VisibleButtons = ({
  curIndex,
  pageCount,
  numVisibleBtns,
  onBtnClick
}) => {
  // Start at index - offset back or index 0
  let flooredMidpoint = parseInt((numVisibleBtns - 1) / 2);
  let start = curIndex < flooredMidpoint ? 0 : curIndex - flooredMidpoint;

  // Start further back if close to last page
  start =
    start > parseInt(pageCount - numVisibleBtns)
      ? parseInt(pageCount - numVisibleBtns)
      : start;

  // Start at index 0 if pageCount <= allowed visible buttons
  start = pageCount - numVisibleBtns <= 0 ? 0 : start;

  const end =
    start + numVisibleBtns < pageCount ? start + numVisibleBtns : pageCount;

  let visibleButtons = [];
  for (let i = start; i < end; i++) {
    visibleButtons.push(
      <Button
        key={i}
        value={i}
        onClick={onBtnClick}
        color={curIndex === i ? "primary" : "outline-light"}
      >
        {i + 1}
      </Button>
    );
  }

  return <ButtonGroup>{visibleButtons}</ButtonGroup>;
};

/**
 * Render a toolbar of numbered buttons to navigate between pages.
 *
 * Displays page numberes centered around current page.
 * Always displays buttons go to last page and go to first.
 * Does not attempt to navigate to current index.
 * @property  {function} onBtnClick    Event handler for button clicks.
 * @property  {number} pageCount       Total number of pages. Base index 1.
 * @property  {number} numVisibleBtns  Max number of buttons to show. Go to first/last not included. Default 7.
 * @property  {number} value           Current page index, default 0. Base index 0.
 */
const CondensedNavButtonBar = ({
  onBtnClick,
  pageCount,
  value = 0,
  numVisibleBtns = 7
}) => {
  const onClick = e => {
    let targetIndex = e.currentTarget.value;
    if (`${targetIndex}` !== `${value}`) onBtnClick(targetIndex);
  };
  return (
    <ButtonToolbar className="d-flex justify-content-center">
      <ButtonGroup>
        <Button value={0} onClick={onClick} color="outline-light">
          <FontAwesomeIcon icon="angle-double-left" />
        </Button>
        <VisibleButtons
          curIndex={value}
          onBtnClick={onClick}
          numVisibleBtns={numVisibleBtns}
          pageCount={pageCount}
        />
        <Button value={pageCount - 1} onClick={onClick} color="outline-light">
          <FontAwesomeIcon icon="angle-double-right" />
        </Button>
      </ButtonGroup>
    </ButtonToolbar>
  );
};

export default CondensedNavButtonBar;
