// Vendor
import React, { useContext } from "react";
import { Row, Col } from "reactstrap";

// App
import { CLUBNAME_VALIDATOR } from "../../../common/Validators";
import { InputField, Select } from "../../ui/FunctionalComponents";
import ProviderCollection from "../../../common/ProviderCollection";
import variables from "../../../styles/bootstrap/_variables.scss";
import Switch from "react-switch";
import ClubAccountManagerSelect from "./ClubAccountManagerSelect";
import { ClubContext } from "../Club";
import { AvForm } from "availity-reactstrap-validation";

function ClubGeneralInfo(props) {
  const { state, dispatch } = useContext(ClubContext);
  const { claimed = false, name, teeSheetProvider } = state;

  const providerList = ProviderCollection.map(provider => provider.label);
  const provider =
    ProviderCollection.find(p => p.key === teeSheetProvider) ||
    ProviderCollection.find(p => p.key === "NONE");

  return (
    <AvForm className="ClubGeneralInfo mt-3">
      <h3>General</h3>
      <Row form>
        <Col md={4}>
          <InputField
            targetKey="name"
            label="Name"
            value={name}
            validate={CLUBNAME_VALIDATOR}
            onChange={event => dispatch({ type: "NAME", payload: event.value })}
          />
        </Col>
        <Col md={4}>
          <Select
            label="Tee sheet provider"
            id={`club-provider`}
            value={provider.label}
            onChange={e => {
              let provider = ProviderCollection.find(
                p => p.label === e.target.value
              );
              dispatch({ type: "TEESHEET_PROVIDER", payload: provider.key });
            }}
          >
            {providerList.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </Select>
        </Col>
        <Col md={4}>
          <ClubAccountManagerSelect />
        </Col>
      </Row>
      <div className="switch-group">
        <Switch
          id="switch-claimed"
          checked={claimed}
          onChange={() => dispatch({ type: "CLAIMED", payload: !claimed })}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={variables.primary}
          offColor={variables.gray300}
          width={50}
        />
        <label
          htmlFor="switch-claimed"
          className={`${claimed ? "" : "text-muted"}`}
        >
          {claimed ? "Claimed" : "Not claimed"}
        </label>
      </div>
    </AvForm>
  );
}

export default ClubGeneralInfo;
