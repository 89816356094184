// Vendor
import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "react-apollo";

// Common
import { ORDER_LIST, ORDER_PAGE_COUNT } from "../../common/Queries";
import URLHelper from "../../common/URLHelper";

// App
import CondensedNavButtonBar from "../ui/CondensedNavButtonBar";
import Loader from "../ui/Loader";
import OrderListTable from "./OrderListTable";

function OrderList(props) {
  const { location } = props.history;

  // Hooks
  const [pageCount, setPageCount] = useState(1);
  const [pageIndex, setPageIndex] = useState(0);
  const [getOrderList, { loading: orderListLoading, data }] = useLazyQuery(
    ORDER_LIST
  );

  // Load page numbers once on mount
  const { loading: pageCountLoading } = useQuery(ORDER_PAGE_COUNT, {
    onCompleted({ orderPageCount }) {
      setPageCount(orderPageCount);
    }
  });

  // Effect hooks
  useEffect(() => {
    // Set page index from url if changed
    let urlPage = URLHelper.getParam(location, "page");
    if (urlPage) setPageIndex(Number(urlPage) - 1);
  }, [location.search]);

  useEffect(() => {
    // Fetch new list on pageIndex change
    getOrderList({ variables: { pageIndex } });
  }, [pageIndex]);

  // Handlers
  function pageBtnClickHandler(newIndex) {
    props.history.push({
      search: `?page=${Number(newIndex) + 1}`
    });
  }

  // Returns
  if (pageCountLoading || orderListLoading || !data)
    return <Loader fullscreen />;

  const { orderList } = data;

  return (
    <div className="page">
      <h1 className="mb-4">Orders</h1>

      <OrderListTable orderList={orderList} />

      <CondensedNavButtonBar
        value={pageIndex}
        onBtnClick={pageBtnClickHandler}
        pageCount={pageCount}
      />
    </div>
  );
}

export default OrderList;
