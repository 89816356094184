import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Popover,
  PopoverBody
} from "reactstrap";
import { getHours, getMinutes } from "../../../../../common/TimeHelper";
import { SeasonContext } from "../Season";
import RatesHelper from "../../../../../common/RatesHelper";

function SeasonDayTypeSetting(props) {
  function togglePopover() {
    setShowPopover(!showPopover);
  }

  // hooks
  const { season } = useContext(SeasonContext);
  const [showPopover, setShowPopover] = useState(false);

  // helpers
  const { isActive, showTimeSelection, scheduleType, dayType } = props;
  const rate = RatesHelper.find(season.rates, { dayType, scheduleType });
  const { endDate, startDate } = rate ? rate : season;
  const buggyIncluded = rate ? rate.buggyIncluded === true : false;

  // id
  const checkboxActiveID = `${scheduleType}-${dayType}-checkbox-active`;
  const checkboxBuggyID = `${scheduleType}-${dayType}-checkbox-buggy`;

  // time
  let hours = getHours();
  let minutes = getMinutes();

  const TimeDropdown = ({ date, onClick, items, format }) => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle size="sm" color="light" outline>
          {moment(date).format(format)}
        </DropdownToggle>
        <DropdownMenu style={{ maxHeight: 200, overflowY: "scroll" }}>
          {items.map((item, i) => (
            <DropdownItem
              key={i}
              onClick={() => {
                onClick(item);
              }}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const TimeDropdownGroup = ({ label, date, field, className = "" }) => (
    <div className={`mb-2 d-inline-block ${className}`}>
      <label>{label}</label>
      <InputGroup size="sm">
        <div className="input-group-prepend">
          <TimeDropdown
            date={date}
            items={hours}
            format="HH"
            onClick={value => {
              props.onChange({
                key: field,
                value: moment(date)
                  .hours(value)
                  .format()
              });
            }}
          />
        </div>
        <div
          className="input-group-append input-group-prepend position-relative"
          style={{ marginLeft: -2, marginRight: -1, zIndex: 3 }}
        >
          <div className="input-group-text">:</div>
        </div>
        <div className="input-group-append">
          <TimeDropdown
            date={date}
            items={minutes}
            format="mm"
            onClick={value => {
              props.onChange({
                key: field,
                value: moment(date)
                  .minutes(value)
                  .format()
              });
            }}
          />
        </div>
      </InputGroup>
    </div>
  );

  return (
    <div className="SeasonDayTypeSetting mb-3">
      <label>{props.label}</label>
      <Card>
        <CardBody className="p-3">
          <div className="checkbox-group">
            <input
              id={checkboxActiveID}
              type="checkbox"
              checked={isActive}
              onChange={event => {
                const { checked } = event.target;
                const hasPrices = rate && rate.prices.length > 0;
                if (isActive && !checked && hasPrices) {
                  setShowPopover(true);
                } else {
                  setShowPopover(false);
                  props.onToggle(checked);
                }
              }}
            />
            <label htmlFor={checkboxActiveID}>
              <small>Active</small>
            </label>
          </div>
          <Popover
            placement="right"
            isOpen={showPopover}
            target={checkboxActiveID}
          >
            <PopoverBody>
              This will remove all prices for this day type!
              <Button
                className="mx-1"
                type="button"
                outline
                color="light"
                size="sm"
                onClick={() => togglePopover()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                color="danger"
                size="sm"
                onClick={() => {
                  togglePopover();
                  props.onToggle(false);
                }}
              >
                OK
              </Button>
            </PopoverBody>
          </Popover>
          {isActive && (
            <>
              {showTimeSelection && (
                <>
                  <TimeDropdownGroup
                    label="Start time"
                    date={startDate}
                    field="startDate"
                    className="mr-2"
                  />
                  <TimeDropdownGroup
                    label="End time"
                    field="endDate"
                    date={endDate}
                  />
                </>
              )}

              <div className="checkbox-group">
                <input
                  id={checkboxBuggyID}
                  type="checkbox"
                  checked={buggyIncluded}
                  onChange={event => {
                    props.onChange({
                      key: "buggyIncluded",
                      value: event.target.checked
                    });
                  }}
                />
                <label htmlFor={checkboxBuggyID}>
                  <small>Buggy included</small>
                </label>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

SeasonDayTypeSetting.propTypes = {
  isActive: PropTypes.bool.isRequired,
  showTimeSelection: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default SeasonDayTypeSetting;
