import React, { Component } from "react";
const withEdit = WrappedComponent => {
  class HOC extends Component {
    constructor(props) {
      super(props);

      this.saveLock = false;

      this.handleSave = this.handleSave.bind(this);
      this.getPropValues = this.getPropValues.bind(this);
    }

    async handleSave(mutation, input) {
      if (this.saveLock) return;
      this.saveLock = true;

      try {
        let { data } = await mutation({ variables: input });
        this.saveLock = false;

        return data[Object.keys(data)[0]];
      } catch (err) {
        this.saveLock = false;
        return { ok: false, message: "Mutation error: Failed to save" };
      }
    }

    getPropValues(edits, data, fields, handler) {
      let props = { onChange: handler };
      for (let field of fields) {
        let value =
          typeof edits[field] !== "undefined" ? edits[field] : data[field];
        if (value !== null) props[field] = value;
      }
      return props;
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          save={this.handleSave}
          getProps={this.getPropValues}
        />
      );
    }
  }

  return HOC;
};
export default withEdit;
