import { library } from "@fortawesome/fontawesome-svg-core";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowDown,
  faArrowRight,
  faArrowUp,
  faBan,
  faBell,
  faBellSlash,
  faCheck,
  faCheckCircle,
  faConciergeBell,
  faCopy,
  faEuroSign,
  faExternalLinkAlt,
  faFileInvoice,
  faGolfBall,
  faMap,
  faPencilAlt,
  faShoppingBasket,
  faShower,
  faSignInAlt,
  faSignOutAlt,
  faSortDown,
  faSortUp,
  faStore,
  faStickyNote,
  faTimes,
  faTachometerAlt,
  faTrashAlt,
  faTv,
  faUpload,
  faUserCircle,
  faUserGraduate,
  faUsers,
  faUtensils
} from "@fortawesome/free-solid-svg-icons";

export default function init() {
  library.add(
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faArrowDown,
    faArrowRight,
    faArrowUp,
    faBan,
    faBell,
    faBellSlash,
    faCalendarAlt,
    faCheck,
    faCheckCircle,
    faConciergeBell,
    faCopy,
    faEuroSign,
    faExternalLinkAlt,
    faFileInvoice,
    faGolfBall,
    faMap,
    faPencilAlt,
    faShoppingBasket,
    faShower,
    faSignInAlt,
    faSignOutAlt,
    faSortDown,
    faSortUp,
    faStore,
    faStickyNote,
    faTachometerAlt,
    faTimes,
    faTrashAlt,
    faTv,
    faUpload,
    faUserCircle,
    faUserGraduate,
    faUsers,
    faUtensils
  );
}
