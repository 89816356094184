// Vendor
import React, { Component, Fragment } from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { Query, withApollo } from "react-apollo";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import { CLUB_SEARCH, CLUB_SEARCH_PAGE } from "../../common/Queries";
import { StatusIcon } from "../ui/StatusIcon";
import ClubSearchInputForm from "./ClubSearchInputForm";
import CondensedNavButtonBar from "../ui/CondensedNavButtonBar";
import Loader from "../ui/Loader";

class ClubSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      sorting: {
        sortOn: "LAST_UPDATED",
        ascending: false
      },
      pageIndex: 0,
      matches: {
        matchCount: 0,
        pageCount: 1
      }
    };

    this.refetchPageRef = () => {};

    this.search = this.search.bind(this);
    this.onPageBtnClick = this.onPageBtnClick.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
  }

  async componentDidMount() {
    const { client } = this.props;

    const { clubSearch } = (await client.query({
      query: CLUB_SEARCH,
      variables: {
        input: {}
      }
    })).data;

    this.setState({ matches: { ...clubSearch } });
  }

  /**
   * Set search filter and fetch page index 0 of new search results.
   */
  async search(input) {
    const { client } = this.props;

    try {
      const { clubSearch } = (await client.query({
        query: CLUB_SEARCH,
        variables: {
          filter: input
        }
      })).data;

      this.setState({
        filter: input,
        pageIndex: 0,
        matches: { ...clubSearch }
      });
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Button event to handle moving from one page of search results to another.
   * @param {number} newIndex  element Button element with value for target page index
   */
  onPageBtnClick(newIndex) {
    this.refetchPageRef({ index: newIndex });
    this.setState({ pageIndex: newIndex });
  }

  handleSortChange(sortOn) {
    this.setState(
      prevState => ({
        sorting:
          prevState.sorting.sortOn === sortOn
            ? { sortOn, ascending: !prevState.sorting.ascending }
            : { sortOn, ascending: false }
      }),
      this.refetchPageRef
    );
  }

  render() {
    const { pageIndex, matches, sorting, filter } = this.state;

    const SortableHeader = ({ children, sortOn }) => (
      <th>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => this.handleSortChange(sortOn)}
        >
          {children}
          {sorting.sortOn === sortOn && (
            <FontAwesomeIcon
              icon={`sort-${sorting.ascending ? "up" : "down"}`}
              className="ml-1"
            />
          )}
        </div>
      </th>
    );

    return (
      <div className="pb-3">
        <h1 className="mb-4">Clubs</h1>
        <ClubSearchInputForm handleSubmit={this.search} />

        <div className="text-black-50">Matches: {matches.matchCount}</div>
        <Query
          query={CLUB_SEARCH_PAGE}
          variables={{ index: pageIndex, sorting, filter }}
        >
          {({ loading, error, data, refetch, networkStatus }) => {
            if (loading || networkStatus === 4)
              return <Loader fullscreen={true} centered />;
            if (error)
              return <h3 className="text-danger">Error fetching clubs</h3>;

            this.refetchPageRef = refetch;

            const { clubSearchPage: pageData } = data;
            return (
              <Table borderless striped>
                <Fragment>
                  <thead>
                    <tr>
                      <SortableHeader sortOn="NAME">Name</SortableHeader>
                      <SortableHeader sortOn="CITY">City</SortableHeader>
                      <SortableHeader sortOn="COUNTRY">Country</SortableHeader>
                      <SortableHeader sortOn="ID">Created</SortableHeader>
                      <SortableHeader sortOn="LAST_UPDATED">
                        Updated
                      </SortableHeader>
                      <th>Claimed</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageData.map((club, index) => (
                      <tr key={index}>
                        <td>{club.name}</td>
                        <td>{club.city}</td>
                        <td>{club.country}</td>
                        <td>
                          {moment(TimestampFromId(club._id)).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </td>
                        <td>
                          {moment(club.updatedAt).format("YYYY-MM-DD HH:mm")}
                        </td>
                        <td>
                          <StatusIcon value={club.claimed} />
                        </td>
                        <td>
                          <Link to={"/club/" + club.slug}>Edit</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Fragment>
              </Table>
            );
          }}
        </Query>

        <CondensedNavButtonBar
          onBtnClick={this.onPageBtnClick}
          pageCount={matches.pageCount}
          value={parseInt(pageIndex)}
        />

        <div className="Save-container">
          <div className="Save-container__phantom" />
          <div className="Save-container__main">
            <Link to="/club/" className="btn btn-secondary mt-2 mb-2">
              New club
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withApollo(ClubSearch);

/**
 * Extract the timestamp from an ObjectId and return as Date
 * @param {string} id ObjectId string representation
 * @returns {Date} Timestamp
 */
function TimestampFromId(id) {
  return new Date(parseInt(id.substring(0, 8), 16) * 1000);
}
