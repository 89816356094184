import React, { useState, useContext } from "react";
import { Row, Col, Button, Popover, PopoverBody } from "reactstrap";
import PropTypes from "prop-types";

import RatesHelper from "../../../../../common/RatesHelper";
import SeasonDayTypeSetting from "./SeasonDayTypeSetting";
import SeasonTable from "./table/SeasonTable";
import { SeasonContext } from "../Season";

export const SeasonScheduleContext = React.createContext(null);
function SeasonSchedule(props) {
  function addInvalidField(field) {
    if (!invalidFields.includes(field)) {
      const array = [...invalidFields, field];
      setInvalidFields(array);
    }
  }

  function getScheduleLabel(scheduleType) {
    switch (scheduleType) {
      case "STANDARD":
        return "Standard rates";
      case "WEEKEND":
        return "Weekend rates";
      default:
        return "Unknown schedule type";
    }
  }

  function removeRates(dayType) {
    const newSeason = { ...season };
    const rates = newSeason.rates.filter(rate => rate.dayType !== dayType);
    dispatch({ type: `SET_${props.scheduleType}_RATES`, payload: rates });
  }

  function removeAllRates() {
    dispatch({ type: `SET_${props.scheduleType}_RATES`, payload: [] });
  }

  function handleRemoveSchedule() {
    setShowPopover(false);
    setScheduleEnabled(false);
    setStandardEnabled(false);
    setEarlyBirdEnabled(false);
    setTwilightEnabled(false);
    removeAllRates();
  }

  function hasRate(rates, dayType) {
    return (
      RatesHelper.find(rates, {
        dayType
      }) !== undefined
    );
  }

  function onSettingsChangeHandler(dayType, event) {
    const { scheduleType } = props;
    let newSeason = { ...season };
    let rates = RatesHelper.filter(newSeason.rates, { scheduleType });
    let rate = RatesHelper.find(rates, { scheduleType, dayType });

    // Create the rate if not exists
    if (!rate) {
      let newRate = {
        dayType,
        scheduleType,
        prices: [],
        startDate: season.startDate,
        endDate: season.endDate
      };
      // override the currently set prop
      newRate[event.key] = event.value;
      rates.push(newRate);
    }
    // edit rate
    else {
      rate[event.key] = event.value;
    }
    dispatch({ type: `SET_${scheduleType}_RATES`, payload: rates });
  }

  function removeInvalidField(field) {
    if (invalidFields.includes(field)) {
      const index = invalidFields.indexOf(field);
      const array = [...invalidFields];
      array.splice(index, 1);
      setInvalidFields(array);
    }
  }

  const { scheduleType } = props;

  // Context values
  const { season, dispatch } = useContext(SeasonContext);
  const rates = RatesHelper.filter(season.rates, {
    scheduleType
  });

  // State
  const [scheduleEnabled, setScheduleEnabled] = useState(rates.length > 0);
  const [invalidFields, setInvalidFields] = useState([]);
  const [editing, setEditing] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [standardEnabled, setStandardEnabled] = useState(
    hasRate(rates, "STANDARD")
  );
  const [earlyBirdEnabled, setEarlyBirdEnabled] = useState(
    hasRate(rates, "EARLY_BIRD")
  );
  const [twilightEnabled, setTwilightEnabled] = useState(
    hasRate(rates, "TWILIGHT")
  );

  const enabledDayTypes = [
    standardEnabled,
    earlyBirdEnabled,
    twilightEnabled
  ].filter(value => value === true);

  React.useEffect(() => {
    // exit edit mode if no day types are enabled
    if (!enabledDayTypes.length && editing) setEditing(false);
  }, [enabledDayTypes]);

  React.useEffect(() => {
    // inform parent if editing or not
    props.toggleEdit(editing);
  }, [editing]);

  const checkboxId = `season-schedule-${scheduleType}`;

  return (
    <SeasonScheduleContext.Provider
      value={{
        invalidFields,
        removeInvalidField,
        addInvalidField,
        editing,
        setEditing,
        standardEnabled,
        earlyBirdEnabled,
        twilightEnabled
      }}
    >
      <div className="SeasonSchedule mt-3">
        <div className="checkbox-group">
          <input
            id={checkboxId}
            type="checkbox"
            checked={scheduleEnabled}
            onChange={event => {
              const { checked } = event.target;
              if (scheduleEnabled && !checked && rates.length > 0) {
                setShowPopover(true);
              } else setScheduleEnabled(checked);
            }}
          />
          <label htmlFor={checkboxId}>{getScheduleLabel(scheduleType)}</label>
        </div>
        <Popover placement="right" isOpen={showPopover} target={checkboxId}>
          <PopoverBody>
            This will remove all {getScheduleLabel(scheduleType).toLowerCase()}!
            <Button
              className="mx-1"
              type="button"
              outline
              color="light"
              size="sm"
              onClick={() => setShowPopover(false)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              color="danger"
              size="sm"
              onClick={handleRemoveSchedule}
            >
              OK
            </Button>
          </PopoverBody>
        </Popover>
        <hr />
        {scheduleEnabled && (
          <Row>
            <Col md={3}>
              <SeasonDayTypeSetting
                label="Standard"
                onToggle={checked => {
                  setStandardEnabled(checked);
                  if (!checked) removeRates("STANDARD");
                }}
                dayType="STANDARD"
                scheduleType={scheduleType}
                onChange={event => onSettingsChangeHandler("STANDARD", event)}
                isActive={standardEnabled}
              />
              <SeasonDayTypeSetting
                showTimeSelection
                label="Early bird"
                onToggle={checked => {
                  setEarlyBirdEnabled(checked);
                  if (!checked) removeRates("EARLY_BIRD");
                }}
                onChange={event => onSettingsChangeHandler("EARLY_BIRD", event)}
                dayType="EARLY_BIRD"
                scheduleType={scheduleType}
                isActive={earlyBirdEnabled}
              />
              <SeasonDayTypeSetting
                showTimeSelection
                label="Twilight"
                onToggle={checked => {
                  setTwilightEnabled(checked);
                  if (!checked) removeRates("TWILIGHT");
                }}
                onChange={event => onSettingsChangeHandler("TWILIGHT", event)}
                dayType="TWILIGHT"
                scheduleType={scheduleType}
                isActive={twilightEnabled}
              />
            </Col>
            <Col md={9}>
              <SeasonTable scheduleType={scheduleType} />
            </Col>
          </Row>
        )}
      </div>
    </SeasonScheduleContext.Provider>
  );
}

SeasonSchedule.propTypes = {
  scheduleType: PropTypes.oneOf(["STANDARD", "WEEKEND"])
};

export default SeasonSchedule;
