// Vendor
import React from "react";
import { Table } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";

function OrderListTable(props) {
  const { orderList } = props;

  return (
    <Table striped borderless>
      <thead>
        <tr>
          <th>Name</th>
          <th className="text-left">Email</th>
          <th className="Col--shrink">Order value</th>
          <th>Order ref</th>
          <th>Created</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody className="border">
        {orderList.map((order, index) => (
          <tr key={index}>
            <td>{`${order.firstName} ${order.lastName}`}</td>
            <td className="text-left">{order.email}</td>
            <td className="text-right">{formatCurrency(order.total)}</td>
            <td>{order.orderReference}</td>
            <td className="Col--shrink">
              {moment(order.createdAt).format("YYYY-MM-DD hh:mm")}
            </td>
            <td className="Col--shrink">
              <Link to={`/order/${order._id}`}>Details</Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

/**
 * Format currency with leading € sign, space as separator and with 2 decimal digits
 * @param {number} value
 */
function formatCurrency(value) {
  return "€" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
}

export default OrderListTable;
