import { Query } from "react-apollo";
import PropTypes from "prop-types";
import React, { useReducer, useState } from "react";
import { Alert, Button, Table } from "reactstrap";

import { IMAGES_BY_TAG } from "../../../../common/Queries";
import { appendScript } from "../../../../common/Scripts";
import Loader from "../../../ui/Loader";
import ClubCourseImageRow from "./ClubCourseImageRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const initialState = null;

function alertReducer(state, action) {
  switch (action.type) {
    case "delete":
      return {
        color: action.payload ? "success" : "danger",
        message: action.payload
          ? "The image has been removed"
          : "Failed to remove image"
      };
    case "rename":
      return {
        color: action.payload ? "success" : "danger",
        message: action.payload
          ? "The image has been renamed"
          : "Failed to rename image"
      };
    case "reset":
      return initialState;
    default:
      throw new Error("No action type found");
  }
}

function ClubCourseImageList(props) {
  function enqueueHideAlert() {
    setTimeout(() => {
      dispatch({ type: "reset" });
    }, 5000);
  }

  function changeHandler(event) {
    dispatch({ type: event.name, payload: event.success });
    enqueueHideAlert();
  }

  // function handleEvents(error, result) {
  //   const { data } = result;
  //   if (data && data.event) {
  //     console.log(data.event);
  //   }
  // }

  function initWidget() {
    const options = {
      cloudName: "greenfee365",
      uploadPreset: "preset-marshalls-course-image-upload",
      sources: ["local"],
      folder: `courses/${props.slug}`,
      publicId: props.slug,
      tags: [props.slug],
      maxImageFileSize: 700000, // 700 kB
      minImageWidth: 1200,
      minImageHeight: 400
    };
    setWidget(window.cloudinary.createUploadWidget(options)); //, handleEvents));
  }

  function loadWidget() {
    // if script is already loaded
    if (window.cloudinary) initWidget();
    else {
      appendScript(
        "https://widget.cloudinary.com/v2.0/global/all.js",
        "cloudinary-upload-widget"
      )
        .then(initWidget)
        .catch(err => {
          console.log(err);
          return err;
        });
    }
  }

  const [alert, dispatch] = useReducer(alertReducer, initialState);
  const [widget, setWidget] = useState(null);
  const { slug } = props;

  React.useEffect(() => {
    if (slug) loadWidget();
  }, []);

  return (
    <div className="ClubCourseImageList mt-5">
      <h3>Images</h3>
      {!slug ? (
        <Alert color="warning">Change slug to load images</Alert>
      ) : (
        <Query
          query={IMAGES_BY_TAG}
          fetchPolicy="cache-and-network"
          variables={{ tag: props.slug }}
        >
          {({ loading, error, data }) => {
            if (error)
              return <Alert color="danger">Could not load course images</Alert>;
            if (loading) return <Loader />;

            return (
              <>
                {alert && <Alert color={alert.color}>{alert.message}</Alert>}
                <Table responsive>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th className="text-left">publicId</th>
                      <th>Dimensions</th>
                      <th>Format</th>
                      <th>Size</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.ImagesByTag.length ? (
                      data.ImagesByTag.map((image, i) => (
                        <ClubCourseImageRow
                          key={i}
                          image={image}
                          imageSize={props.imageSize}
                          onChange={changeHandler}
                        />
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center text-muted">
                          No images found for this course
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Button
                  color="light"
                  outline
                  onClick={() => {
                    if (widget) widget.open();
                  }}
                >
                  <FontAwesomeIcon icon="upload" className="mr-1" /> Upload
                </Button>
              </>
            );
          }}
        </Query>
      )}
    </div>
  );
}

ClubCourseImageList.propTypes = {
  imageSize: PropTypes.number,
  slug: PropTypes.string
};

ClubCourseImageList.defaultProps = {
  imageSize: 40
};

export default ClubCourseImageList;
