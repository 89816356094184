// Vendor
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

// App
import {
  NUM_DECIMAL_VALIDATOR_REQ,
  INT_VALIDATOR
} from "../../../common/Validators";
import { InputField } from "../../ui/FunctionalComponents";

class KeyFigureList extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    keyFigures: PropTypes.shape({
      courses: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      sunnyDays: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      beerPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      taxiPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  };
  static defaultProps = {
    keyFigures: {
      courses: 0,
      sunnyDays: 0,
      beerPrice: 0,
      taxiPrice: 0
    }
  };
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ key, value }) {
    const { onChange, keyFigures } = this.props;
    onChange({ key: "keyFigures", value: { ...keyFigures, [key]: value } });
  }

  render() {
    const { courses, sunnyDays, beerPrice, taxiPrice } = this.props.keyFigures;
    return (
      <div className="mb-5">
        <h3 className="mb-3">Key Figures</h3>
        <Row>
          <Col md={3}>
            <InputField
              onChange={this.handleChange}
              targetKey="courses"
              value={`${courses}`}
              label="Golf courses"
              validate={INT_VALIDATOR}
              options={{ required: true, type: "number" }}
            />
          </Col>
          <Col md={3}>
            <InputField
              onChange={this.handleChange}
              targetKey="sunnyDays"
              value={`${sunnyDays}`}
              label="Sunny days"
              validate={INT_VALIDATOR}
              options={{ required: true, type: "number" }}
              isRequired
            />
          </Col>
          <Col md={3}>
            <InputField
              onChange={this.handleChange}
              targetKey="beerPrice"
              value={`${beerPrice}`}
              label="Beer price (pint, €)"
              validate={NUM_DECIMAL_VALIDATOR_REQ}
              options={{ type: "number" }}
            />
          </Col>
          <Col md={3}>
            <InputField
              onChange={this.handleChange}
              targetKey="taxiPrice"
              value={`${taxiPrice}`}
              label="Taxi (1km)"
              validate={NUM_DECIMAL_VALIDATOR_REQ}
              options={{ type: "number" }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default KeyFigureList;
