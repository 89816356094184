// Vendor
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

// App
import Category from "../Category";

const languagePropType = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string
});

class CategoryList extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        imgId: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        i18n: PropTypes.shape({
          "es-ES": languagePropType,
          "sv-SE": languagePropType,
          "de-DE": languagePropType,
          "da-DK": languagePropType,
          "fi-FI": languagePropType,
          "fr-FR": languagePropType,
          "it-IT": languagePropType,
          "nl-NL": languagePropType,
          "nb-NO": languagePropType,
          "ru-RU": languagePropType
        })
      })
    )
  };
  static defaultProps = {
    categories: []
  };

  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0
    };

    this.newCategory = this.newCategory.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
  }

  newCategory() {
    const { onChange, categories } = this.props;
    const defaultValues = { title: "", description: "" };

    onChange({
      key: "categories",
      value: [
        ...categories,
        {
          imgId: "",
          title: "",
          description: "",
          i18n: {
            "es-ES": { ...defaultValues },
            "sv-SE": { ...defaultValues },
            "de-DE": { ...defaultValues },
            "da-DK": { ...defaultValues },
            "fi-FI": { ...defaultValues },
            "fr-FR": { ...defaultValues },
            "it-IT": { ...defaultValues },
            "nl-NL": { ...defaultValues },
            "nb-NO": { ...defaultValues },
            "ru-RU": { ...defaultValues }
          }
        }
      ]
    });
    this.setState({ tabIndex: categories.length });
  }

  onCategoryChange(category) {
    const { categories, onChange } = this.props;
    const { tabIndex } = this.state;

    let arrayClone = [...categories];
    arrayClone[tabIndex] = category;

    onChange({
      key: "categories",
      value: arrayClone
    });
  }

  deleteCategory() {
    const { categories, onChange } = this.props;
    const { tabIndex } = this.state;

    let filteredCopy = categories.filter((_, i) => i !== tabIndex);

    onChange({
      key: "categories",
      value: filteredCopy
    });

    if (tabIndex !== 0 && filteredCopy.length === tabIndex)
      this.setState(prevState => ({ tabIndex: prevState.tabIndex - 1 }));
  }

  render() {
    const { categories } = this.props;
    const { tabIndex } = this.state;

    return (
      <div className="mb-5">
        <h3>Categories</h3>

        <Nav tabs>
          {categories.map((item, i) => (
            <NavItem key={i}>
              <NavLink
                active={i === tabIndex}
                onClick={() => this.setState({ tabIndex: i })}
                className={`${item.title ? "" : "font-italic"}`}
              >
                {item.title || "Untitled"}
              </NavLink>
            </NavItem>
          ))}
          <NavItem>
            <NavLink className="text-primary" onClick={this.newCategory}>
              +New category
            </NavLink>
          </NavItem>
        </Nav>

        {categories.length > 0 && (
          <TabContent>
            <TabPane>
              <Category
                onChange={this.onCategoryChange}
                onDelete={this.deleteCategory}
                category={categories[tabIndex]}
              />
            </TabPane>
          </TabContent>
        )}
      </div>
    );
  }
}

export default CategoryList;
