// Vendor
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { Alert, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import Loader from "../../ui/Loader";
import { LINKABLE_COURSES } from "../../../common/Queries";

class GolfCourseList extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    fullCoursesObjects: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        i18n: PropTypes.object
      })
    )
  };
  static defaultProps = {
    fullCoursesObjects: []
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.addGolfCourse = this.addGolfCourse.bind(this);
    this.moveClubUp = this.moveClubUp.bind(this);
    this.moveClubDown = this.moveClubDown.bind(this);
    this.removeClub = this.removeClub.bind(this);
  }

  addGolfCourse(club) {
    const { fullCoursesObjects, onChange } = this.props;
    if (fullCoursesObjects.some(c => c._id === club._id)) return;
    onChange({
      key: "fullCoursesObjects",
      value: [...fullCoursesObjects, club]
    });
  }

  moveClubUp(club) {
    const { fullCoursesObjects, onChange } = this.props;
    let curIndex = fullCoursesObjects.findIndex(c => c._id === club._id);
    if (curIndex === -1) return;

    // Cannot move up from top
    if (curIndex === 0) return;
    let reordered = fullCoursesObjects.slice(0, curIndex - 1);
    reordered.push(club);
    reordered.push(fullCoursesObjects[curIndex - 1]);
    if (curIndex !== fullCoursesObjects.length - 1)
      reordered = reordered.concat(fullCoursesObjects.slice(curIndex + 1));
    onChange({ key: "fullCoursesObjects", value: reordered });
  }

  moveClubDown(club) {
    const { fullCoursesObjects, onChange } = this.props;
    let curIndex = fullCoursesObjects.findIndex(c => c._id === club._id);
    if (curIndex === -1) return;

    // Cannot move down from bottom
    if (curIndex === fullCoursesObjects.length - 1) return;

    let reordered = fullCoursesObjects.slice(0, curIndex);
    reordered.push(fullCoursesObjects[curIndex + 1]);
    reordered.push(club);
    if (curIndex !== fullCoursesObjects.length - 2)
      reordered = reordered.concat(fullCoursesObjects.slice(curIndex + 2));
    onChange({ key: "fullCoursesObjects", value: reordered });
  }

  removeClub(club) {
    const { onChange, fullCoursesObjects } = this.props;
    onChange({
      key: "fullCoursesObjects",
      value: fullCoursesObjects.filter(c => c._id !== club._id)
    });
  }

  render() {
    const { fullCoursesObjects } = this.props;

    const ClubRow = ({ club }) => {
      const langs = club.i18n ? Object.keys(club.i18n).length + 1 : 0;
      let translationCount = 0;
      if (club.description && club.description.length > 0) translationCount++;
      if (club.i18n) {
        for (let lang of Object.keys(club.i18n)) {
          if (club.i18n[lang].description.length > 0) translationCount++;
        }
      }
      return (
        <tr>
          <td style={{ width: "1px", whiteSpace: "nowrap" }}>
            <FontAwesomeIcon
              icon="arrow-down"
              onClick={() => this.moveClubDown(club)}
              className="d-inline-flex mr-2"
            />
            <FontAwesomeIcon
              icon="arrow-up"
              onClick={() => this.moveClubUp(club)}
              className="d-inline-flex"
            />
          </td>
          <td>{club.name}</td>
          <td
            className={`text-${
              translationCount === langs ? "success" : "warning"
            }`}
          >{`${translationCount}/${langs}`}</td>
          <td>
            <FontAwesomeIcon
              className="text-muted"
              icon="trash-alt"
              onClick={() => this.removeClub(club)}
            />
          </td>
        </tr>
      );
    };

    return (
      <div className="container mb-5">
        <h3 className="row mb-3">Golf courses</h3>

        <div className="row">
          <Query query={LINKABLE_COURSES} fetchPolicy="cache-and-network">
            {({ loading, data, error }) => {
              if (error)
                return (
                  <Alert color="danger">Could not load linked courses</Alert>
                );
              if (loading) return <Loader />;

              const addableCourses = data.getActiveCourses.sort((a, b) =>
                a.name > b.name ? 1 : -1
              );

              /**
               * Cannot use value={item} & call onChange as normal
               * Value passed as the string "[Object][Object]"
               */
              return (
                <select
                  className="col-md form-control form-control-lg mr-4 mt-md-5"
                  value="default"
                  onChange={e =>
                    this.addGolfCourse(addableCourses[e.target.value])
                  }
                >
                  <option value="default" disabled>
                    Select golf course to add
                  </option>
                  {addableCourses.map((item, i) => (
                    <option value={i} key={i}>
                      {item.name}
                    </option>
                  ))}
                </select>
              );
            }}
          </Query>
          <div className="col-md pr-0">
            <Table striped borderless>
              {fullCoursesObjects.length > 0 ? (
                <>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th>Descriptions</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody className="border">
                    {fullCoursesObjects.map((club, i) => (
                      <ClubRow club={club} key={i} />
                    ))}
                  </tbody>
                </>
              ) : (
                <>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody className="border">
                    <tr>
                      <td className="text-muted text-center">
                        No courses added
                      </td>
                    </tr>
                  </tbody>
                </>
              )}
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default GolfCourseList;
