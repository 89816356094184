import gql from "graphql-tag";
import { START_PAGE_FIELDS } from "./GraphQLFields";

// partials

export const SEASON_RATE_ATTRIBUTES = `
  _id
  seasonalType
  startDate
  endDate
  rates{
    scheduleType
    dayType
    startDate
    endDate
    prices{
      price
      rackPrice
      rateType
      multiplier
      targetRateId
      useTeeSheetProviderPricing
    }
    buggyIncluded
  }
`;

export const COURSE_ATTRIBUTES = `
  _id
  slug
  teeSheetCourseId
  name
  holes
  par
  tees{
    name
    targetId
  }
  cancellationHours
  amenities
  address
  city
  state
  country
  countryCode
  phone
  membership
  courseType
  architect
  #currency
  active
  description
  i18n
  areas
  placeId
  localTimeZone
  loc {
    type
    coordinates
  }
  seasonRates {
    ${SEASON_RATE_ATTRIBUTES}
  }
`;

export const CLUB_ATTRIBUTES = `
  _id
  name
  slug
  address
  city
  state
  country
  countryCode
  phone
  website
  teeSheetClubId
  teeSheetProvider
  claimed
  #claimedDate
  accountManager{
    _id
    firstName
    lastName
  }
  contacts {
    name
    title
    email
  }
  courses {
    ${COURSE_ATTRIBUTES}
  }
  #updatedAt
  #deleted
  #deletedAt
`;

// CLUBS
export const GET_CLUB_BY_ID_QUERY = gql`
  query GetClubById($id: ID!) {
    getClubById(_id: $id) {
      ${CLUB_ATTRIBUTES}
    }
  }
`;

export const CLUB_GET_BY_SLUG = gql`
  query GetClubBySlug($slug: String) {
    getClubBySlug(slug: $slug) {
      ${CLUB_ATTRIBUTES}
    }
  }
`;

export const CLUB_SEARCH_PAGE = gql`
  query ClubSearchPage(
    $index: Int!
    $sorting: ClubSortInput!
    $filter: ClubFilterOptionInput
  ) {
    clubSearchPage(pageIndex: $index, sorting: $sorting, filter: $filter) {
      _id
      name
      city
      country
      claimed
      slug
      updatedAt
    }
  }
`;

export const CLUB_SEARCH = gql`
  query ClubSearch($filter: ClubFilterOptionInput) {
    clubSearch(filter: $filter) {
      matchCount
      pageCount
    }
  }
`;

export const CLUBS_EXPIRING_RATES = gql`
  query RatesExpirationStatus($days: Int) {
    ratesExpirationStatus(days: $days) {
      _id
      name
      slug
      ratedDaysLeft
    }
  }
`;

export const LINKABLE_COURSES = gql`
  query getActiveCourses {
    getActiveCourses {
      _id
      name
      description
      i18n
    }
  }
`;

export const CLUBS_MISSING_AVAILABILITY = gql`
  query clubsMissingAvailability {
    clubsMissingAvailability {
      name
      slug
      teeSheetProvider
    }
  }
`;

// GOLF AREAS
export const GOLF_AREAS = gql`
  query GolfAreas {
    GolfAreas {
      _id
      name
      slug
      country
      countryCode
      active
      courses {
        _id
      }
    }
  }
`;

export const GOLF_AREA_BY_SLUG = gql`
  query GolfArea($slug: String!) {
    GolfArea(slug: $slug) {
      _id
      name
      slug
      coordinates {
        latitude
        longitude
      }
      radius
      zoom
      country
      countryCode
      imgId
      keyFigures {
        courses
        sunnyDays
        taxiPrice
        beerPrice
      }
      active
      startPageIndex
      description
      summary
      i18n
      categories {
        imgId
        title
        description
        i18n
      }
      fullCoursesObjects {
        _id
        name
        description
        i18n
      }
      cities {
        name
        imgId
      }
    }
  }
`;

// START PAGE
export const START_PAGE = gql`
  query StartPage {
    startPage {
      ${START_PAGE_FIELDS}
    }
  }
`;

export const IMAGES_BY_TAG = gql`
  query ImagesByTag($tag: String!) {
    ImagesByTag(tag: $tag) {
      publicId
      width
      height
      format
      bytes
    }
  }
`;

// ACCOUNT MANAGERS
export const ACCOUNT_MANAGERS = gql`
  query AccountManagers {
    accountManagers {
      _id
      firstName
      lastName
      #email
      #updatedAt
    }
  }
`;

// RATES
export const MISSING_RATES = gql`
  query missingRates($muted: Boolean) {
    missingRates(muted: $muted) {
      _id
      name
      rateTypeId
      muted
      clubId {
        name
        slug
        teeSheetProvider
      }
      rateEndDate
    }
  }
`;

export const ORDER_PAGE_COUNT = gql`
  query orderPageCounty {
    orderPageCount
  }
`;

export const ORDER_LIST = gql`
  query orderList($pageIndex: Int!) {
    orderList(pageIndex: $pageIndex) {
      _id
      firstName
      lastName
      email
      total
      orderReference
      createdAt
    }
  }
`;

export const ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      _id
      firstName
      lastName
      total
      email
      orderReference
      createdAt
      clubs {
        total
        clubId
        name
        teetimes {
          cancelled
          bookingDetailsIds
          price
          players
          buggies
          courseId
          playDate
          cancelDateTime
          bookingErrors {
            reason
          }
          ratesUsed {
            price
            rackPrice
            rateType
            targetRateId
            players
          }
        }
      }
    }
  }
`;
