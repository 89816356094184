import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

function InputTable(props) {
  function handleAdd() {
    const { onChange } = props;
    onChange([...items, inputValues]);
    setInputValues(getDefaultValues());

    // reset form
    ref && ref.reset();
  }

  function handleRemove(pill) {
    const { items, onChange } = props;
    onChange(items.filter(item => item !== pill));
  }

  function getDefaultValues() {
    let initialValues = {};
    // add empty values for all field keys
    props.fields.forEach(field => {
      initialValues[field.key] = "";
    });
    return initialValues;
  }

  const [ref, setRef] = useState(null);
  const { fields, items } = props;
  const [inputValues, setInputValues] = useState(getDefaultValues());

  return (
    <div className="InputTable">
      <AvForm ref={ref => setRef(ref)} onValidSubmit={() => handleAdd()}>
        <div className="d-flex align-items-start mb-2">
          {fields.map(field => (
            <AvField
              key={field.key}
              groupAttrs={{ className: "d-inline-block mb-0 mr-2" }}
              name={`input-${field.key}`}
              value={inputValues[field.key]}
              placeholder={field.label}
              onChange={e => {
                let values = { ...inputValues };
                values[field.key] = e.target.value;
                setInputValues(values);
              }}
              onKeyPress={e => {
                if (e.charCode === 13) {
                  e.target.blur();
                }
              }}
              type="text"
              validate={field.validator}
              required
            />
          ))}
          <div>
            <Button color="primary">Add</Button>
          </div>
        </div>
      </AvForm>
      <Table responsive striped className="border-0">
        <thead>
          <tr>
            {fields.map((field, index) => (
              <th className="text-left" key={index}>
                {field.label}
              </th>
            ))}
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody className="border">
          {items.length > 0 ? (
            items.map((item, i) => (
              <tr key={i}>
                {fields.map(field => (
                  <td key={field.key} className="text-left">
                    {item[field.key]}
                  </td>
                ))}
                <td className="text-right">
                  <Button
                    color="link"
                    className="text-danger py-0"
                    onClick={() => handleRemove(item)}
                  >
                    <FontAwesomeIcon icon="trash-alt" />
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={fields.length + 1}>No items added</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

InputTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      validator: PropTypes.shape.isRequired,
      label: PropTypes.string
    })
  ).isRequired,
  imageField: PropTypes.string
};
InputTable.defaultProps = {
  items: [],
  fields: [],
  imageField: ""
};

export default InputTable;
