// Vendor
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "reactstrap";

// App
import FlagIcon from "./FlagIcon";

export default class SingleTranslationTable extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    targetKey: PropTypes.string.isRequired,
    value: PropTypes.string,
    i18n: PropTypes.object,
    placeholderText: PropTypes.string
  };
  static defaultProps = {
    value: "",
    i18n: {
      "es-ES": {},
      "sv-SE": {},
      "de-DE": {},
      "da-DK": {},
      "fi-FI": {},
      "fr-FR": {},
      "it-IT": {},
      "nl-NL": {},
      "nb-NO": {},
      "ru-RU": {}
    },
    placeholderText: "No value found..."
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedLang: "",
      currentText: ""
    };

    this.changeActiveLang = this.changeActiveLang.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.saveChange = this.saveChange.bind(this);
    this.unselectLanguage = this.unselectLanguage.bind(this);
  }

  changeActiveLang(lang) {
    const { i18n, targetKey, value } = this.props;
    this.setState({
      selectedLang: lang,
      currentText: lang === "en-GB" ? value : i18n[lang][targetKey]
    });
  }

  onTextChange(e) {
    this.setState({ currentText: e.target.value });
  }

  onKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.saveChange();
    } else if (e.key === "Escape") {
      e.preventDefault();
      this.setState({ selectedLang: "" });
    }
  }

  saveChange() {
    const { onChange, i18n, targetKey } = this.props;
    const { currentText, selectedLang } = this.state;
    if (selectedLang === "en-GB") {
      onChange({ key: targetKey, value: currentText });
    } else {
      const i18nCopy = { ...i18n };
      i18nCopy[selectedLang] = {
        ...i18nCopy[selectedLang],
        [targetKey]: currentText
      };
      onChange({ key: "i18n", value: i18nCopy });
    }
    this.setState({ selectedLang: "" });
  }

  unselectLanguage() {
    this.setState({ selectedLang: "" });
  }

  render() {
    const { targetKey, value, i18n, placeholderText } = this.props;
    const { selectedLang, currentText } = this.state;
    const InactiveRow = ({ lang }) => {
      const text = lang === "en-GB" ? value : i18n[lang][targetKey];
      return (
        <tr>
          <td style={{ width: "71px" }}>
            <FlagIcon code={lang.substr(3).toLowerCase()} className="mr-1" />
            {lang.substr(0, 2).toUpperCase()}
          </td>
          <td className={`text-left${text ? "" : " text-muted"}`}>
            <div className="w-100 text-truncate">{text || placeholderText}</div>
          </td>
          <td style={{ width: "140px" }}>
            <Button
              className="float-right d-inline-flex p-0"
              color="link"
              onClick={() => this.changeActiveLang(lang)}
            >
              Edit
            </Button>
          </td>
        </tr>
      );
    };

    const ActiveRow = ({ lang }) => {
      return (
        <tr>
          <td style={{ width: "71px" }}>
            <FlagIcon code={lang.substr(3).toLowerCase()} className="mr-1" />
            {lang.substr(0, 2).toUpperCase()}
          </td>
          <td>
            <input
              autoFocus
              value={currentText}
              onKeyDown={this.onKeyDown}
              onChange={this.onTextChange}
              className="pl-2 w-100"
            />
          </td>
          <td style={{ width: "140px" }}>
            <Button
              className="d-inline p-0 pl-2 pr-2"
              color="primary"
              onClick={this.saveChange}
            >
              OK
            </Button>
            <Button
              className="btn btn-outline-light d-inline p-0 pl-2 pr-2 ml-2"
              onClick={this.unselectLanguage}
            >
              Cancel
            </Button>
          </td>
        </tr>
      );
    };

    return (
      <Table
        borderless
        striped
        className="border w-100"
        style={{ tableLayout: "fixed" }}
      >
        <tbody>
          {selectedLang === "en-GB" ? (
            <ActiveRow lang="en-GB" />
          ) : (
            <InactiveRow lang="en-GB" />
          )}
          {Object.keys(i18n).map((lang, i) =>
            lang === selectedLang ? (
              <ActiveRow lang={lang} key={i} />
            ) : (
              <InactiveRow lang={lang} key={i} />
            )
          )}
        </tbody>
      </Table>
    );
  }
}
