// App
import ApolloClientProvider from "./ApolloClientProvider";
// import { Promise } from "promise/lib/es6-extensions";
import { USER_SIGNIN } from "./Mutations";

const USER_TOKEN_KEY = "gf365m-token";
const USER_KEY = "gf365m-user";

class Auth {
  static getToken() {
    return localStorage.getItem(USER_TOKEN_KEY);
  }

  static getUser() {
    const user = localStorage.getItem(USER_KEY);
    if (!user) return null;
    return JSON.parse(user);
  }

  static isAuthenticated() {
    return localStorage.getItem(USER_TOKEN_KEY) !== null;
  }

  static login(email, password) {
    return new Promise((resolve, reject) => {
      ApolloClientProvider.client
        .mutate({
          mutation: USER_SIGNIN,
          variables: { email, password }
        })
        .then(res => {
          const { email, firstName, lastName } = res.data.signin.user;

          // Ensure that the UI and store state reflects the current user’s permissions
          // This will cause the store to be cleared and all active queries to be refetched.
          ApolloClientProvider.client.resetStore();

          this.setToken(res.data.signin.token);

          // Populate user obj, but dont inherit props from base class
          let userObj = { email, firstName, lastName };
          this.setUser(userObj);

          // EventEmitterClient.emit("LOGIN");

          resolve(true);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  static logout() {
    // Ensure that the UI and store state reflects the current user’s permissions
    // This will cause the store to be cleared and all active queries to be refetched.
    ApolloClientProvider.client.resetStore();

    // Remove token and user from local storage
    this.clearStorage();

    // EventEmitterClient.emit("LOGOUT");
  }

  static clearStorage() {
    localStorage.removeItem(USER_TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
    window.location.reload();
  }

  // Store auth token in local storage so user stays logged in
  static setToken(token) {
    localStorage.setItem(USER_TOKEN_KEY, token);
  }

  static setUser(user) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }
}

export default Auth;
