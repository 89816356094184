// Vendor
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

// Common
import CountryCollection from "../../../common/CountryCollection";
import {
  SLUG_VALIDATOR,
  CLUBNAME_VALIDATOR,
  NUM_DECIMAL_VALIDATOR_REQ,
  INT_VALIDATOR
} from "../../../common/Validators";
import debounce from "../../../common/debounce";

// App
import { InputField } from "../../ui/FunctionalComponents";
import ImageIdCard from "../../ui/ImageIdCard";
import FlagIcon from "../../ui/FlagIcon";

class GeneralInfo extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    slug: PropTypes.string,
    country: PropTypes.string,
    countryCode: PropTypes.string,
    imgId: PropTypes.string,
    coordinates: PropTypes.shape({
      latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    zoom: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };
  static defaultProps = {
    name: "",
    slug: "",
    country: "",
    countryCode: "",
    imgId: "",
    coordinates: { latitude: 0, longitude: 0 },
    radius: 1,
    zoom: 0
  };

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.onImgIdChange = this.onImgIdChange.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.onCoordsChange = this.onCoordsChange.bind(this);
  }

  handleCountryChange(e) {
    const { onChange } = this.props;
    const country = CountryCollection.find(c => c.code === e.target.value);
    onChange({ key: "country", value: country.name });
    onChange({
      key: "countryCode",
      value: country.code
    });
  }

  onImgIdChange = debounce(e => {
    this.props.onChange(e);
  }, 500);

  dropdownToggle() {
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
  }

  onCoordsChange({ key, value }) {
    const { coordinates, onChange } = this.props;
    let coordCopy = { ...coordinates };
    coordCopy[key] = value;
    onChange({ key: "coordinates", value: coordCopy });
  }

  render() {
    const {
      onChange,
      name,
      slug,
      country,
      countryCode,
      imgId,
      coordinates,
      radius,
      zoom
    } = this.props;
    const { dropdownOpen } = this.state;
    return (
      <div className="mt-4 mb-5">
        <h3>General Information</h3>

        <div className="row mt-3">
          <div className="col-md mb-3">
            <ImageIdCard
              publicId={imgId}
              onChangeTargetKey="imgId"
              onChange={this.onImgIdChange}
            />
          </div>
          <div className="col-md">
            <InputField
              targetKey="name"
              value={name}
              label="Name"
              onChange={onChange}
              validate={CLUBNAME_VALIDATOR}
              options={{ required: true, type: "text" }}
            />

            <InputField
              targetKey="slug"
              value={slug}
              label="Slug"
              onChange={onChange}
              validate={SLUG_VALIDATOR}
              options={{ required: true, type: "text" }}
            />

            <div className="row">
              <div className="col">
                <InputField
                  targetKey="latitude"
                  value={coordinates.latitude}
                  label="Latitude"
                  onChange={this.onCoordsChange}
                  validate={NUM_DECIMAL_VALIDATOR_REQ}
                  options={{ required: true, type: "number" }}
                />
              </div>

              <div className="col">
                <InputField
                  targetKey="longitude"
                  value={coordinates.longitude}
                  label="Longitude"
                  onChange={this.onCoordsChange}
                  validate={NUM_DECIMAL_VALIDATOR_REQ}
                  options={{ required: true, type: "number" }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <InputField
                  targetKey="radius"
                  value={radius}
                  label="Radius (km)"
                  onChange={onChange}
                  validate={NUM_DECIMAL_VALIDATOR_REQ}
                  options={{ required: true, type: "number" }}
                />
              </div>

              <div className="col">
                <InputField
                  targetKey="zoom"
                  value={zoom}
                  label="Zoom level (0-19)"
                  onChange={onChange}
                  validate={INT_VALIDATOR}
                  options={{ required: true, type: "number" }}
                />
              </div>
            </div>

            <div className="float-input-group form-group">
              <Dropdown
                id="country-dropdown"
                className="dropdown dropdown-fancy d-block"
                isOpen={dropdownOpen}
                toggle={this.dropdownToggle}
              >
                <DropdownToggle
                  caret
                  className="btn btn-outline-light d-block w-100"
                >
                  <span className="float-left">
                    {countryCode && (
                      <FlagIcon code={countryCode} className="mr-2" />
                    )}
                    {country}
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  {CountryCollection.map((country, i) => (
                    <DropdownItem
                      value={country.code}
                      key={i}
                      onClick={this.handleCountryChange}
                    >
                      {country.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <label htmlFor="country-dropdown">Country</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralInfo;
