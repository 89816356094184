// Vendor
import React from "react";
import { Row, Col } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";

// App
import ClubMap from "../../ClubMap";
import { NUM_DECIMAL_VALIDATOR_REQ } from "../../../../common/Validators";
import { ClubCourseContext } from "../ClubCourse";
import { useContext } from "react";
import { AvForm } from "availity-reactstrap-validation";

function ClubCourseLocation(props) {
  const { state, dispatch } = useContext(ClubCourseContext);
  const loc = state.loc || { type: "Point", coordinates: [0.0, 0.0] };
  const placeId = state.placeId || "";

  function handleChange({ newCoords = null, placeId = null }) {
    if (newCoords)
      dispatch({
        type: "LOC",
        payload: {
          type: loc.type,
          coordinates: newCoords
        }
      });
    if (placeId)
      dispatch({
        type: "PLACE_ID",
        payload: placeId
      });
  }

  function getInputField(isLat) {
    const { coordinates } = loc;
    const i = isLat ? 0 : 1;
    const label = isLat ? "Latitude" : "Longitude";
    const id = isLat ? "club-lat" : "club-lng";

    return (
      <AvField
        groupAttrs={{ className: "float-input-group" }}
        name={id}
        id={id}
        label={label}
        value={`${coordinates[i]}`}
        validate={NUM_DECIMAL_VALIDATOR_REQ}
        placeholder="0.0"
        onBlur={e => handleCoordInputBlur(i, e.target.value)}
      />
    );
  }

  function handleCoordInputBlur(coordIndex, value) {
    if (
      NUM_DECIMAL_VALIDATOR_REQ.pattern.value.test(value) &&
      loc.coordinates[coordIndex] !== Number(value)
    ) {
      let coordinatesCopy = [...loc.coordinates];
      coordinatesCopy[coordIndex] = Number(value);
      handleChange({ newCoords: coordinatesCopy });
    }
  }

  return (
    <AvForm className="ClubCourseLocation mt-5">
      <h3>Location</h3>
      <Row form>
        <Col md={4}>{getInputField(true)}</Col>
        <Col md={4}>{getInputField(false)}</Col>
        <Col md={4}>
          <AvField
            groupAttrs={{ className: "float-input-group" }}
            name="club-place-id"
            id="club-place-id"
            label="Google place id"
            value={`${placeId}`}
            onBlur={e => handleChange({ placeId: e.target.value })}
          />
        </Col>
      </Row>
      <ClubMap coordinates={loc.coordinates} onClick={handleChange} />
    </AvForm>
  );
}

export default ClubCourseLocation;
