export default function clubReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return action.payload;
    case "ACCOUNT_MANAGER":
      return { ...state, accountManager: action.payload };
    case "ADDRESS":
      return { ...state, address: action.payload };
    case "CITY":
      return { ...state, city: action.payload };
    case "CLAIMED":
      return { ...state, claimed: action.payload };
    case "CONTACTS":
      return { ...state, contacts: action.payload };
    case "COUNTRY":
      return { ...state, country: action.payload };
    case "COUNTRY_CODE":
      return { ...state, countryCode: action.payload };
    case "COURSES_SAVE_NEW":
      let courses = [...state.courses];
      const courseIndex = courses.findIndex(c => c._id === undefined);
      courses[courseIndex] = action.payload;
      return { ...state, courses };
    case "COURSE_ADD":
      return { ...state, courses: [...state.courses, action.payload] };
    case "NAME":
      return { ...state, name: action.payload };
    case "PHONE":
      return { ...state, phone: action.payload };
    case "STATE":
      return { ...state, state: action.payload };
    case "TEESHEET_PROVIDER":
      return { ...state, teeSheetProvider: action.payload };
    case "WEBSITE":
      return { ...state, website: action.payload };
    default:
      return state;
  }
}
